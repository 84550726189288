import React from 'react';

import "./welcome.scss";

const Welcome = () => {

    return (
        <>
            <div className="welcome-container">
                <h2>Welcome!</h2>
            </div>            
        </>
    );
}

export default Welcome