import * as React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Welcome from "../components/welcome"

const IndexPage = () => (
  <Layout>
    <SEO/>
    <Welcome/>
  </Layout>
)

export default IndexPage